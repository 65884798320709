import { qualifyUrls } from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const SalesforceRoutes = {
  INTEGRATIONS: {
    Salesforce: {
      ACTIVITYAPI: {
        index: '/integrations/salesforce',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Salesforce: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/salesforce/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/salesforce/checkCredentials',
      GET_LOG_TYPES: '/plugins/org.graylog.enterprise.integrations/salesforce/getLogTypes',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Salesforce: {
      GRAYLOG_Salesforce_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_Salesforce_ActivityLog_Input.html#graylog-Salesforce-activitylog-input',
    },
  },
};

const qualifiedRoutes = AppConfig.gl2AppPathPrefix() ? qualifyUrls(SalesforceRoutes, AppConfig.gl2AppPathPrefix()) : SalesforceRoutes;

export default {
  ...qualifiedRoutes,
  unqualified: SalesforceRoutes,
};

export { DocsRoutes, ApiRoutes };
