import { qualifyUrls } from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const DefenderRoutes = {
  INTEGRATIONS: {
    Defender: {
      ACTIVITYAPI: {
        index: '/integrations/microsoftdefender',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    Defender: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/microsoftdefender/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/microsoftdefender/checkCredentials',

    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    Defender: {
      GRAYLOG_Defender_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_defender_ActivityLog_Input.html#graylog-defender-activitylog-input',
    },
  },
};

const qualifiedRoutes = AppConfig.gl2AppPathPrefix() ? qualifyUrls(DefenderRoutes, AppConfig.gl2AppPathPrefix()) : DefenderRoutes;

export default {
  ...qualifiedRoutes,
  unqualified: DefenderRoutes,
};

export { DocsRoutes, ApiRoutes };
