import { qualifyUrls } from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const SymantecEDRRoutes = {
  INTEGRATIONS: {
    SymantecEDR: {
      ACTIVITYAPI: {
        index: '/integrations/symantecedr',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    SymantecEDR: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/symantecedr/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/symantecedr/checkCredentials',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    SymantecEDR: {
      GRAYLOG_SymantecEDR_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_symantecEDR_ActivityLog_Input.html#graylog-symantecEDR-activitylog-input',
    },
  },
};

const qualifiedRoutes = AppConfig.gl2AppPathPrefix() ? qualifyUrls(SymantecEDRRoutes, AppConfig.gl2AppPathPrefix()) : SymantecEDRRoutes;

export default {
  ...qualifiedRoutes,
  unqualified: SymantecEDRRoutes,
};

export { DocsRoutes, ApiRoutes };
