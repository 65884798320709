import { qualifyUrls } from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const OidcRoutes = {
  AUTH_CALLBACK: '/authorization-code/callback',
} as const;

const qualifiedRoutes = AppConfig.gl2AppPathPrefix() ? qualifyUrls(OidcRoutes, AppConfig.gl2AppPathPrefix()) : OidcRoutes;

export default {
  ...qualifiedRoutes,
  unqualified: OidcRoutes,
};
