import { qualifyUrls } from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const O365Routes = {
  INTEGRATIONS: {
    O365: {
      ACTIVITYAPI: {
        index: '/integrations/o365',
      },
    },
  },
};

const ApiRoutes = {
  INTEGRATIONS: {
    O365: {
      CONTENT_TYPES: '/plugins/org.graylog.enterprise.integrations/office365/checkSubscriptions',
      START_SUBSCRIPTION: '/plugins/org.graylog.enterprise.integrations/office365/startSubscription',
      START_MOCK_SUBSCRIPTION: '/plugins/org.graylog.enterprise.integrations/office365/startMockSubscription',
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/office365/inputs',
    },
  },
};

const DocsRoutes = {
  INTEGRATIONS: {
    O365: {
      GRAYLOG_O365_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_O365_ActivityLog_Input.html#graylog-o365-activitylog-input',
    },
  },
};

const qualifiedRoutes = AppConfig.gl2AppPathPrefix() ? qualifyUrls(O365Routes, AppConfig.gl2AppPathPrefix()) : O365Routes;

export default {
  ...qualifiedRoutes,
  unqualified: O365Routes,
};

export { DocsRoutes, ApiRoutes };
